@import '@styles/mixins.sass'

.container
    padding: 120px 160px
    @include for-small-desktop
        padding: 100px 80px
    @include for-tablet
        padding: 80px 60px
    @include for-tablet-portrait
        padding: 60px 40px
    @include for-phone
        padding: 50px 20px

.headerContainer
    margin-bottom: 60px
    @include for-small-desktop
        margin-bottom: 50px
    @include for-tablet
        margin-bottom: 40px
    @include for-tablet-portrait
        margin-bottom: 30px
    h1
        font-size: 160px
        font-weight: 900
        color: var(--text-color)
        @include for-small-desktop
            font-size: 140px
        @include for-tablet
            font-size: 120px
        @include for-tablet-portrait
            font-size: 100px
        @include for-phone
            font-size: 80px
            text-align: center

.smallHeaderContainer
    margin-bottom: 60px
    @include for-small-desktop
        margin-bottom: 50px
    @include for-tablet
        margin-bottom: 40px
    @include for-tablet-portrait
        margin-bottom: 30px
    p
        font-size: 40px
        font-weight: 500
        color: var(--text-color)
        @include for-small-desktop
            font-size: 34px
        @include for-tablet
            font-size: 28px
        @include for-tablet-portrait
            font-size: 22px
            line-height: 26px
        @include for-phone
            text-align: center
    a
        color: var(--accent-color-2)
        transition: color .3s ease-in-out
        &:hover
            color: var(--accent-color)